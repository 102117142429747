// routing
import Routes from "routes";
// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";
import ThemeCustomization from "themes";
// auth provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import NotFound from "notFound";
import { SocketProvider } from "contexts/SocketContext";
import "./index.css";

// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
  <ThemeCustomization>
    <RTLLayout>
      <Locales>
        <NavigationScroll>
          <SocketProvider>
            <AuthProvider>
              <Toaster />
              <>
                <Routes />
                {/* <Routes > */}
                <Snackbar />
              </>
            </AuthProvider>
          </SocketProvider>
        </NavigationScroll>
      </Locales>
    </RTLLayout>
  </ThemeCustomization>
);

export default App;
