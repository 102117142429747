import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  GraminSathi: [],
};
//internally using immer lib (can create mutable state)
export const graminSathiSlice = createSlice({
  name: "graminSathiData",
  initialState,
  reducers: {
    updateAllGraminSathi: (state, action) => {
      state.GraminSathi = action.payload;
    },
   
  },
});
// this is for dispatch
export const { updateAllGraminSathi } = graminSathiSlice.actions;
// this is for configureStore
export default graminSathiSlice.reducer;
