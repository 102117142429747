import { createSlice } from "@reduxjs/toolkit";

const initialState = {
Trans : [],
UserOrder :[],
FranchiseOrder :[],

};

//internally using immer lib (can create mutable state)
export const transactionSlice = createSlice({
  name: "tranData",
  initialState,
  reducers: {
    updateAllTransaction: (state, action) => {
      state.Trans = action.payload;
    },
    updateAllUserOrder : (state, action) => {
      state.UserOrder = action.payload;
    },
    updateAllFranchiseOrder : (state, action) => {
      state.FranchiseOrder = action.payload;
    },
  },
});

// this is for dispatch
export const { updateAllTransaction,updateAllUserOrder,updateAllFranchiseOrder } = transactionSlice.actions;

// this is for configureStore
export default transactionSlice.reducer;
