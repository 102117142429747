import React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, useMediaQuery, Switch, Button } from "@mui/material";

// project imports
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import LocalizationSection from "./LocalizationSection";
import MegaMenuSection from "./MegaMenuSection";
import WithdrawApi from "../../../api/withdraw.api";
import NotificationSection from "./NotificationSection";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useDispatch, useSelector } from "store";
import { openDrawer } from "store/slices/menu";
import { getUserLocal } from "utils/localStorage.util";
import { inchargeNameChange } from "utils/common.utils";
import DashboardApi from "api/dashboard.api";
import { useEffect, useState, useCallback } from "react";
import { toast } from "react-hot-toast";

import { updateWallet } from "../../../redux/redux-slice/dashBoard.slice";

// assets
import { IconMenu2 } from "@tabler/icons";
import user from "store/slices/user";
import { Balance } from "@mui/icons-material";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  // wallet balance

  const dashboardApi = new DashboardApi();

  const getDashboard = useCallback(async () => {
    try {
      const dashboardData = await dashboardApi.getDashboard();
      if (!dashboardData || !dashboardData.data) {
        return toast.error("No Data  available");
      } else {
        if (dashboardData.data.code == "200") {
          // setData(dashboardData.data);

          dispatch(updateWallet(dashboardData.data.walletBalance));
        }
        return;
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
      throw error;
    }
  });

  useEffect(() => {
    getDashboard();
  }, []);

  const wallet = useSelector((state) => state.dashboard.Wallet);

  /// USER WALLET

  const withdrawApi = new WithdrawApi();
  const [balance, setBalance] = useState("");

  const getWalletByFrenchiseId = useCallback(async () => {
    try {
      const WalletById = await withdrawApi.GetWalletByFrenchiseId({
        frenchiseId: userId.staffId,
      });
      console.log(
        WalletById.data.data.balance,
        "=========================================="
      );

      setBalance(WalletById.data.data.balance);
      if (WalletById && WalletById?.data?.code === 200) {
      } else {
        return toast.error(`Something went wrong!`);
      }
    } catch (error) {
      console.error(error);
      // toast.error("Something went wrong");
      throw error;
    }
  });

  const theme = useTheme();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const userId = getUserLocal();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { layout } = useConfig();

  let isAdmin = false;
  // Check if the user is an Admin
  if (userId && userId.type === "Admin") {
    isAdmin = true;
  }

  useEffect(() => {
    if (!isAdmin) {
      getWalletByFrenchiseId();
    }
  }, []);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection type={userId?.Type} />
        </Box>

        {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
        (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
              },
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        ) : null}
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* mega-menu */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <b>{inchargeNameChange(userId?.type).toUpperCase()}</b>
        {/* <MegaMenuSection /> */}
      </Box>

      {/* live customization & localization */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        {/* <LocalizationSection /> */}
        {/* <Switch onCh color="warning" /> */}
      </Box>

      {/* wallet balance */}

      <Button
        startIcon={<AccountBalanceWalletOutlinedIcon />}
        sx={{
          ml: 1,
          backgroundColor: "#E6F4F1",
          transition: "all ease-in-out 0.3s",
          color: "#279d85",
          "&:hover": {
            backgroundColor: "#279d85",
            color: "#E6F4F1",
          },
        }}
      >
        ₹{isAdmin ? wallet : balance}
      </Button>

      {/* notification & profile */}
      <NotificationSection />
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
