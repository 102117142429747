import { getTokenLocal } from "../utils/localStorage.util";
import ApiRoutes from "../conffigs/endpoints.config";
import HttpClient from "./index.api";
const baseURL = process.env.REACT_APP_API_URL;

class Withdraw extends HttpClient {
  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  WithdrawConfig = ApiRoutes.Withdraw.All;
  WithdrawHistoryConfig = ApiRoutes.Withdraw.GetRequest
  WithdrawEditConfig = ApiRoutes.Withdraw.EditWithdrawa
  WithdrawByIdConfig = ApiRoutes.Withdraw.GetById
  WithdrawaHistoryByIdConfig = ApiRoutes.Withdraw.GetWithdrawaHistoryById
  AddWithdrawConfig= ApiRoutes.Withdraw.AddWithdraw;
  GetWalletByFrenchiseIdConfig= ApiRoutes.Withdraw.GetWalletByFrenchiseId;
  getAllRequest = async () => {
    return this.instance({
      method: this.WithdrawConfig.Method,
      url: this.WithdrawConfig.Endpoint,
      headers: {},
      data: null,
    });
  };

  getAllWithdrawHistory = async () => {
    return this.instance({
      method: this.WithdrawHistoryConfig.Method,
      url: this.WithdrawHistoryConfig.Endpoint,
      headers: {},
      data: null,
    });
  };

  GetAllWithdrawEdit = async (data) => {
    return this.instance({
      method: this.WithdrawEditConfig.Method,
      url: this.WithdrawEditConfig.Endpoint,
      headers: {},
      data: data,
    });
  };

  GetWithdrawById = async (data) => {
    return this.instance({
      method: this.WithdrawByIdConfig.Method,
      url: this.WithdrawByIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };

  GetWithdrawHistoryById = async (data) => {
    return this.instance({
      method: this.WithdrawaHistoryByIdConfig.Method,
      url: this.WithdrawaHistoryByIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  AddWithdraw = async (data) => {
    return this.instance({
      method: this.AddWithdrawConfig.Method,
      url: this.AddWithdrawConfig.Endpoint,
      headers: {},
      data: data,
    });
  };


  GetWalletByFrenchiseId = async (data) => {
    return this.instance({
      method: this.GetWalletByFrenchiseIdConfig.Method,
      url: this.GetWalletByFrenchiseIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
 
}

export default Withdraw;
