import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Farmer: [],
};
//internally using immer lib (can create mutable state)
export const farmerSlice = createSlice({
  name: "farmerData",
  initialState,
  reducers: {
    updateAllFarmer: (state, action) => {
      state.Farmer = action.payload;
    },
  },
});
// this is for dispatch
export const { updateAllFarmer } = farmerSlice.actions;
// this is for configureStore
export default farmerSlice.reducer;
