import { getTokenLocal } from "../utils/localStorage.util";
import ApiRoutes from "../conffigs/endpoints.config";
import HttpClient from "./index.api";
const baseURL = process.env.REACT_APP_API_URL;

class Dashboard extends HttpClient {
  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  DashboardConfig = ApiRoutes.Dashboard.Data;
  FranchiseDashboardConfig = ApiRoutes.Dashboard.FranchiseData;
  StaffDetailConfig = ApiRoutes.Dashboard.staffDetail;
  KhetiNotificationConfig = ApiRoutes.Dashboard.getNotification;
  NotificationReadConfig = ApiRoutes.Dashboard.readNotification;
  WalletConfig = ApiRoutes.Dashboard.wallet;


  notificationRead = async () => {
    return this.instance({
      method: this.NotificationReadConfig.Method,
      url: this.NotificationReadConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  getNotification = async () => {
    return this.instance({
      method: this.KhetiNotificationConfig.Method,
      url: this.KhetiNotificationConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  getDashboard = async () => {
    return this.instance({
      method: this.DashboardConfig.Method,
      url: this.DashboardConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  
  getStaffDetail = async () => {
    return this.instance({
      method: this.StaffDetailConfig.Method,
      url: this.StaffDetailConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  getFranchiseDashboard = async () => {
    return this.instance({
      method: this.FranchiseDashboardConfig.Method,
      url: this.FranchiseDashboardConfig.Endpoint,
      headers: {},
      data: null,
    });
  };

  getWallet = async (data) => {
    return this.instance({
      method: this.WalletConfig.Method,
      url: this.WalletConfig.Endpoint,
      headers: {},
      data: data,
    });
  }
}

export default Dashboard;
