import { FormattedMessage } from "react-intl";
import { getUserLocal } from "utils/localStorage.util";

// assets
import {
  IconCategory,
  IconShoppingCart,
  IconBuildingStore,
  IconReport,
  IconMilitaryRank,
  IconBuildingSkyscraper,
  IconUsers,
  IconFileText,
  IconQuestionMark,
  IconFolder,
  IconBooks,
  IconListDetails,
  IconCash,
  IconBasket,
  IconLicense,
  IconSitemap,
  IconStackPush,
  IconZoomInArea,
  IconKeyframes,
  IconChartArea,
  IconAtom2,
  IconDashboard,
  IconServicemark,
  IconBrandSlack,
  IconHotelService,
  IconLayersDifference,
  IconBell,
  IconSend,
  IconCloud,
  IconPlus,
  IconAugmentedReality,
  IconHistory,
  IconConfetti,
  IconVirusSearch,
  IconInfoCircle,
  IconMedicineSyrup,
  IconFileInvoice,
  IconBuildingBank,
  IconCreativeCommonsNc,
  IconCoinRupee,
  IconAffiliate,
  IconLeaf,
  IconLayoutCollage,
  IconWallet,
  IconReportMoney,
  IconSortDescending2,
  IconHistoryToggle
} from "@tabler/icons";

// constant
const icons = {
  IconCategory,
  IconShoppingCart,
  IconBuildingStore,
  IconReport,
  IconMilitaryRank,
  IconBuildingSkyscraper,
  IconUsers,
  IconFileText,
  IconQuestionMark,
  IconFolder,
  IconBooks,
  IconListDetails,
  IconCash,
  IconBasket,
  IconLicense,
  IconSitemap,
  IconStackPush,
  IconZoomInArea,
  IconKeyframes,
  IconChartArea,
  IconAtom2,
  IconFileInvoice,
  IconDashboard,
  IconServicemark,
  IconBrandSlack,
  IconHotelService,
  IconLayersDifference,
  IconSend,
  IconCloud,
  IconAugmentedReality,
  IconHistory,
  IconPlus,
  IconConfetti,
  IconInfoCircle,
  IconVirusSearch,
  IconMedicineSyrup,
  IconBell,
  IconBuildingBank,
  IconCreativeCommonsNc,
  IconCoinRupee,
  IconAffiliate,
  IconLeaf,
  IconLayoutCollage,
  IconWallet,
  IconReportMoney,
  IconSortDescending2,
  IconHistoryToggle

};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const userId = getUserLocal();
var Parent = [];
if (userId) {
  if (userId?.type === "Admin") {
    Parent = [
      {
        id: "default",
        title: "Dashboard",
        type: "item",
        url: "/dashboard",
        icon: icons.IconDashboard,
        breadcrumbs: false,
      },
      {
        id: "banner",
        title: "Banner",
        type: "item",
        url: "/banner",
        icon: icons.IconBooks,
      },
      {
        id: "Product",
        title: "Product",
        type: "collapse",
        icon: icons.IconLicense,
        breadcrumbs: false,
        children: [
          {
            id: "category",
            title: "Category",
            type: "item",
            url: "/category",
            icon: icons.IconCategory,
          },
          {
            id: "sub-category",
            title: "Sub Category",
            type: "item",
            url: "/sub-category",
            icon: icons.IconCategory,
          },
          {
            id: "product",
            title: "Product",
            type: "item",
            url: "/product",
            icon: icons.IconLicense,
          },
        ],
      },
      {
        id: "service",
        title: "Service",
        type: "collapse",
        icon: icons.IconServicemark,
        breadcrumbs: false,
        children: [
          {
            id: "serviceCategory",
            title: "Category",
            type: "item",
            url: "/get-service-category",
            icon: icons.IconCategory,
          },
          {
            id: "serviceProduct",
            title: "Service",
            type: "item",
            url: "/get-services",
            icon: icons.IconBrandSlack,
          },
          {
            id: "serviceType",
            title: "Type",
            type: "item",
            url: "/get-service-type",
            icon: icons.IconHotelService,
          },
          {
            id: "servicePackage",
            title: "Package",
            type: "item",
            url: "/get-service-package",
            icon: icons.IconLayersDifference,
          },
          {
            id: "gramin-sathi-commission",
            title: "Gramin Sathi Commission",
            type: "item",
            url: "/gramin-sathi-commission",
            icon: icons.IconCreativeCommonsNc,
          },
        ],
      },
      {
        id: "inventory",
        title: "Inventory Management",
        type: "item",
        url: "/inventory",
        icon: icons.IconBuildingStore,
      },
      // {
      //   id: "wallet history",
      //   title: "Wallet History",
      //   type: "item",
      //   url: "/wallet-history",
      //   icon: icons.IconWallet,
      //   breadcrumbs: false,
      // },
      {
        id: "Users Wallet",
        title: "Users  Wallet",
        type: "item",
        url: "/user-wallet",
        icon: icons.IconWallet,
        breadcrumbs: false,
      },
      {
        id: "withdrawal request",
        title: "Withdrawal Request",
        type: "item",
        url: "/withdrawal-request",
        icon: icons.IconReportMoney,
        breadcrumbs: false,
        // children: [
        //   {
        //     id: "withdrawal history",
        //     title: "Withdrawal history",
        //     type: "item",
        //     url: "/withdrawal-history",
        //     breadcrumbs: false,
        //   }, {
        //     id: "withdrawal request",
        //     title: "Withdrawal Request",
        //     type: "item",
        //     url: "/withdrawal-request",
        //     breadcrumbs: false,
        //   },


        // ],
      },
      {
        id: "franchise",
        title: "Franchise",
        type: "collapse",
        icon: icons.IconBuildingSkyscraper,
        breadcrumbs: false,
        children: [
          {
            id: "franchise",
            title: "Total Franchise",
            type: "item",
            url: "/franchise",
            breadcrumbs: false,
          },
          {
            id: "franchise-request",
            title: "Franchise Request",
            type: "item",
            url: "/franchise-request",
            breadcrumbs: false,
          },
          {
            id: "franchise-request-form",
            title: "Add Franchise",
            type: "item",
            url: "/franchise-request-form",
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "graminSathi",
        title: "Gramin Sathi",
        type: "collapse",
        icon: icons.IconAffiliate,
        breadcrumbs: false,
        children: [
          {
            id: "graminSathi",
            title: "Total Gramin Sathi",
            type: "item",
            url: "/gramin-sathi",
            breadcrumbs: false,
          },
          {
            id: "graminSathiRequest",
            title: "Gramin Sathi Request",
            type: "item",
            url: "/gramin-sathi-request",
            breadcrumbs: false,
          },
          {
            id: "gramin-sathi-form",
            title: "Add Gramin Sathi",
            type: "item",
            url: "/gramin-sathi-form",
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "RSVC",
        title: "RSVC",
        type: "collapse",
        icon: icons.IconLeaf,
        breadcrumbs: false,
        children: [
          {
            id: "RSVC",
            title: "Total RSVC",
            type: "item",
            url: "/rsvc",
            breadcrumbs: false,
          },
          // {
          //   id: "RSVC-request",
          //   title: "RSVC Request",
          //   type: "item",
          //   url: "/rsvc-request",
          //   breadcrumbs: false,
          // },
          {
            id: "rsvc-request-form",
            title: "Add RSVC",
            type: "item",
            url: "/rsvc-form",
            breadcrumbs: false,
          },
        ],
      },

      {
        id: "SC",
        title: "SC",
        type: "collapse",
        icon: icons.IconLayoutCollage,
        breadcrumbs: false,
        children: [
          {
            id: "SC",
            title: "Total SC",
            type: "item",
            url: "/sc",
            breadcrumbs: false,
          },
          // {
          //   id: "sc-request",
          //   title: "SC Request",
          //   type: "item",
          //   url: "/sc-request",
          //   breadcrumbs: false,
          // },
          {
            id: "sc-form",
            title: "Add SC",
            type: "item",
            url: "/sc-form",
            breadcrumbs: false,
          },
        ],
      },

      {
        id: "commission",
        title: "Commission",
        type: "item",
        url: "/commission",
        icon: icons.IconCoinRupee,
        breadcrumbs: false,
      },


      {
        id: "loan",
        title: "Loan",
        type: "collapse",
        icon: icons.IconBuildingBank,
        breadcrumbs: false,
        children: [
          {
            id: "loan",
            title: "Loan",
            type: "item",
            url: "/loan-detail",
            breadcrumbs: false,
          },
          {
            id: "add-loan",
            title: "Add Loan",
            type: "item",
            url: "/add-loan",
            breadcrumbs: false,
          },

        ],
      },
      {
        id: "franchise-order",
        title: "Franchise Order",
        type: "item",
        icon: icons.IconChartArea,
        breadcrumbs: false,
        url: "/franchise-order",
      },
      {
        id: "farmer-service-request",
        title: "Farmer Service Request",
        type: "item",
        icon: icons.IconSortDescending2,
        url: "/farmer-service-request",
        breadcrumbs: false,
      },
      {
        id: "transaction-history",
        title: "Transaction History",
        type: "item",
        url: "/transaction-history",
        icon: icons.IconCash,
      },
      {
        id: "ledger-history",
        title: "Ledger History",
        type: "item",
        url: "/ledger-history",
        icon: icons.IconLicense,
      },
      {
        id: "settlement",
        title: "Settlement History",
        type: "collapse",
        icon: icons.IconCash,
        children: [
          {
            id: "product-settlement",
            title: "Product Settlement",
            type: "item",
            url: "/product-settlement",
            breadcrumbs: false,
          }, {
            id: "service-settlement",
            title: "Service Settlement",
            type: "item",
            url: "/service-settlement",
            breadcrumbs: false,
          },
        ],
        // icon: icons.IconFileInvoice,
      },
      {
        id: "farmer",
        title: "Farmers",
        type: "item",
        icon: icons.IconUsers,
        url: "/users",
        breadcrumbs: false,
      },
      {
        id: "staffmanagement",
        title: "Staff Management",
        type: "collapse",
        icon: icons.IconMilitaryRank,
        children: [
          {
            id: "role",
            title: "Roles",
            type: "item",
            url: "/role",
            breadcrumbs: false,
          },
          {
            id: "staff-users",
            title: "Staff Users",
            type: "item",
            url: "/staff-users",
            breadcrumbs: false,
          },
        ],
      },

      // {
      //   id: "hrm",
      //   title: "Hrm",
      //   type: "collapse",
      //   icon: icons.IconKeyframes,
      //   breadcrumbs: false,
      //   children: [
      //     {
      //       id: "attedance",
      //       title: "Attedance",
      //       type: "item",
      //       url: "/attedance",
      //       breadcrumbs: false,
      //     },
      //     {
      //       id: "holidays",
      //       title: "Hoilidays",
      //       type: "item",
      //       url: "/holidays",
      //       breadcrumbs: false,
      //     },
      //   ],
      // },
      {
        id: "lead",
        title: "Lead",
        type: "collapse",
        icon: icons.IconKeyframes,
        breadcrumbs: false,
        children: [
          {
            id: "product-lead",
            title: "Product Lead",
            type: "item",
            url: "/product-lead",
            breadcrumbs: false,
          },
          {
            id: "service-lead",
            title: "Service Lead",
            type: "item",
            url: "/service-lead",
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "area",
        title: "Area",
        type: "collapse",
        icon: icons.IconZoomInArea,
        breadcrumbs: false,
        children: [
          {
            id: "State",
            title: "State",
            type: "item",
            url: "/state",
            breadcrumbs: false,
          },
          {
            id: "district",
            title: "District",
            type: "item",
            url: "/district",
            breadcrumbs: false,
          },
          {
            id: "pin-code",
            title: "Pin Code",
            type: "item",
            url: "/pin-code",
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "advisory",
        title: "Advisory",
        type: "collapse",
        icon: icons.IconSend,
        breadcrumbs: false,
        children: [
          {
            id: "weather",
            title: "Weather",
            type: "collapse",
            icon: icons.IconCloud,
            breadcrumbs: false,
            children: [
              {
                id: "Add-Weather",
                title: "Weather Forecast",
                type: "item",
                // icon: icons.IconPlus,
                url: "/send-weather-notifications",
                breadcrumbs: false,
              },
              {
                id: "notifaction-weather",
                title: "Forecast History",
                // icon: icons.IconHistory,
                type: "item",
                url: "/weather-notifications-advisory",
                breadcrumbs: false,
              },
            ],
          },
          {
            id: "Personalized-Crop",
            title: "Personalized Crop",
            type: "collapse",
            icon: icons.IconAugmentedReality,
            breadcrumbs: false,
            children: [
              {
                id: "Add-Personalized",
                title: "Personalized Advisory",
                type: "item",
                // icon: icons.IconPlus,
                url: "/send-personalised-notifications",
                breadcrumbs: false,
              },
              {
                id: "notifaction-personalised",
                title: "Advisory History",
                // icon: icons.IconHistory,
                type: "item",
                url: "/notifications-personalised",
                breadcrumbs: false,
              },
            ],
          },
          {
            id: "fertiliser",
            title: "Fertigation",
            type: "collapse",
            icon: icons.IconConfetti,
            breadcrumbs: false,
            children: [
              {
                id: "add-feartigation",
                title: "Fertigation Advisory",
                type: "item",
                // icon: icons.IconPlus,
                url: "/send-fertiliser-notifications",
                breadcrumbs: false,
              },
              {
                id: "notifaction-fertigation",
                title: "Fertigation History",
                // icon: icons.IconHistory,
                type: "item",
                url: "/notifications-fertiliser",
                breadcrumbs: false,
              },
            ],
          },
          {
            id: "weather",
            title: "Pesticide",
            type: "collapse",
            icon: icons.IconMedicineSyrup,
            breadcrumbs: false,
            children: [
              {
                id: "add-pesticide",
                title: "Pesticide Advisory",
                type: "item",
                // icon: icons.IconPlus,
                url: "/send-pesticide-notifications",
                breadcrumbs: false,
              },
              {
                id: "notifaction-pesticide",
                title: "Pesticide History",
                // icon: icons.IconHistory,
                type: "item",
                url: "/notifications-pesticide",
                breadcrumbs: false,
              },
            ],
          },
          {
            id: "disease",
            title: "Disease Alert",
            type: "collapse",
            icon: icons.IconVirusSearch,
            breadcrumbs: false,
            children: [
              {
                id: "Add-Disease",
                title: "Disease Advisory",
                type: "item",
                // icon: icons.IconPlus,
                url: "/send-disease-notifications",
                breadcrumbs: false,
              },
              {
                id: "notifaction-disease",
                title: "Disease History",
                // icon: icons.IconHistory,
                type: "item",
                url: "/notifications-disease",
                breadcrumbs: false,
              },
            ],
          },
          {
            id: "information",
            title: "Important Information",
            type: "collapse",
            icon: icons.IconInfoCircle,
            breadcrumbs: false,
            children: [
              {
                id: "Add-Information",
                title: "Send Information",
                type: "item",
                // icon: icons.IconPlus,
                url: "/send-information-notifications",
                breadcrumbs: false,
              },
              {
                id: "notifaction-information",
                title: "Information History",
                // icon: icons.IconHistory,
                type: "item",
                url: "/notifications-information",
                breadcrumbs: false,
              },
            ],
          },
        ],
      },
      
    ];
  }
   else if (userId?.type === "SVC"||"FR"||"RSVC"||"GS") {
    Parent = [
      {
        id: "default",
        title: "Dashboard",
        type: "item",
        url: "/franchiseDashboard",
        icon: icons.IconDashboard,
        breadcrumbs: false,
      },
      // {
      //   id: "User Wallet",
      //   title: "User  Wallet",
      //   type: "item",
      //   url: "/user-wallet",
      //   icon: icons.IconWallet,
      //   breadcrumbs: false,
      // },
     
      {
        id: "Our-Products",
        title: <FormattedMessage id="Our-Products" />,
        type: "collapse",
        icon: icons.IconBasket,
        children: [
          {
            id: "products",
            title: <FormattedMessage id="products" />,
            type: "item",
            url: "/our/products",
          },
          {
            id: "checkout",
            title: <FormattedMessage id="checkout" />,
            type: "item",
            url: "/our/checkout",
          },
        ],
      },
      {
        id: "add-new-service",
        title: "Add New Sevice Request",
        type: "item",
        url: "/farmer-register-form",
        icon: icons.IconCash,
      },
      {
        id: "lead",
        title: "LEAD",
        type: "collapse",
        icon: icons.IconKeyframes,
        breadcrumbs: false,
        children: [
          {
            id: "product-lead",
            title: "Product Lead",
            type: "item",
            url: "/product-lead",
            breadcrumbs: false,
          },
          {
            id: "service-lead",
            title: "Service Lead",
            type: "item",
            url: "/service-lead",
            breadcrumbs: false,
          },
        ],
      },
      {
        id: "transaction-history",
        title: "Franchise Transactions",
        type: "item",
        url: "/frenchise-transaction-history",
        icon: icons.IconCash,
      },
      // {
        
      //     id: "wallet--history",
      //     title: "Wallet history",
      //     type: "item",
      //     url: "/withdrawal-transection",
      //     breadcrumbs: false,
        
      // },
      
      {
        id: "withdrawal history",
        title: "Withdrawal history",
        type: "item",
        url: "/withdrawal-history",
        icon: icons.IconReportMoney,
        breadcrumbs: false,
      },
      {
        id: "wallet history",
        title: "Wallet history",
        type: "item",
        url: "/Wallet-history",
        icon: icons.IconHistoryToggle,
        breadcrumbs: false,
      },
      {
        id: "farmer-register-form",
        title: "ADD Farmer",
        type: "item",
        url: "/FarmerRegistration-form",
        icon: icons.IconBuildingStore,
      },
      {
        id: "farmer-order",
        title: "Farmer- Order",
        type: "item",
        icon: icons.IconChartArea,
        url: "/frenchise-order-history",
        breadcrumbs: false,
      },
      {
        id: "farmer",
        title: "Farmer",
        type: "item",
        url: "/frenchise-users",
        icon: icons.IconUsers,
      },
      {
        id: "franchise-card",
        title: "Card",
        type: "item",
        url: "/frenchise-card",
        icon: icons.IconFileText,
      },
    ];
  }
}

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: Parent,
};

export default utilities;
